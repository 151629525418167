import _pick from 'lodash/pick';
import { useCallback, useMemo, useState } from 'react';

import type { IFilters } from '@src/@types/microcredits';
import { FILTERS_NAMES } from '@src/constants/filters';
import { useQueryReplace } from '@src/hooks/useQueryReplace';
import { useCurrentLocation } from '@src/reducers/locations';

import type { CalculatorParams, CalculatorState } from '../types';

const DEFAULT_CALCULATOR_VALUES: CalculatorState = {
    penaltyRate: 0.0546,
    amount: 10000,
    rate: 0.8,
    term: 10,
    currency: 'RUB',
    metadata: true,
    penaltyTerm: undefined,
};

const buildQuery = (location: string) => (state: CalculatorState) => ({ ...state, location });

const getStateFromParams = (params: IFilters, defaultState: CalculatorState) =>
    _pick(params, Object.keys(defaultState));

export const useCalculatorOffers = (filters: Partial<IFilters>) => {
    const defaultState = useMemo(
        () => ({
            [FILTERS_NAMES.AMOUNT]: filters?.[FILTERS_NAMES.AMOUNT] ?? DEFAULT_CALCULATOR_VALUES.amount,
            [FILTERS_NAMES.RATE]: filters?.[FILTERS_NAMES.RATE] ?? DEFAULT_CALCULATOR_VALUES.rate,
            [FILTERS_NAMES.TERM]: filters?.[FILTERS_NAMES.TERM] ?? DEFAULT_CALCULATOR_VALUES.term,
            [FILTERS_NAMES.PENALTY_RATE]: DEFAULT_CALCULATOR_VALUES.rate,
            [FILTERS_NAMES.PENALTY_TERM]: DEFAULT_CALCULATOR_VALUES.penaltyTerm,
            [FILTERS_NAMES.CURRENCY]: filters?.[FILTERS_NAMES.CURRENCY] ?? DEFAULT_CALCULATOR_VALUES.currency,
            [FILTERS_NAMES.METADATA]: filters?.[FILTERS_NAMES.METADATA] ?? DEFAULT_CALCULATOR_VALUES.metadata,
        }),
        [filters],
    );

    const [state, setState] = useState<CalculatorState>(() => ({
        ...defaultState,
        ...getStateFromParams(filters, defaultState),
    }));

    const location = useCurrentLocation().route;
    const defaultQuery = useMemo(() => ({ ...defaultState, location }), [defaultState, location]);
    const updateQuery = useQueryReplace<IFilters>();
    const getQuery = buildQuery(location);

    const updateCalculator = useCallback(
        (state: CalculatorState) => {
            const updatedQuery = { newQuery: getQuery(state), defaultQuery };

            // Исключаем penaltyTerm, тк при изменении другого поля, оно обновить квери параметр и возьмет его значение из стейта
            if (FILTERS_NAMES.PENALTY_TERM in updatedQuery.newQuery) {
                delete updatedQuery.newQuery.penaltyTerm;
            }

            updateQuery(updatedQuery);
        },
        [defaultQuery, getQuery, updateQuery],
    );

    const setValue = useCallback(
        (key: CalculatorParams, value: number | undefined) => {
            setState((state) => {
                if (state[key] === value) return state;

                const updatedState = { ...state, [key]: value };

                if (key === FILTERS_NAMES.PENALTY_TERM) {
                    // Обновляем только состояние, но не вызываем updateCalculator,
                    // тк это поле не передается на бэк для фильтрации продуктов,
                    // оно необходимо только для расчета суммы штрафа
                    return updatedState;
                }

                // Вызываем updateCalculator для всех ключей, кроме penaltyTerm
                updateCalculator(updatedState);

                return updatedState;
            });
        },
        [updateCalculator],
    );

    const resetValues = useCallback(
        (resetValues: CalculatorParams[]): void => {
            setState((state) => resetValues.reduce((acc, item) => ({ ...acc, [item]: defaultState[item] }), state));
        },
        [defaultState],
    );

    return {
        values: state,
        setValue,
        resetValues,
    };
};
