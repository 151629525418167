import { useRouter } from 'next/router';

import { useHasUtmLabel } from '@src/reducers/route';

import { PAGES_WITH_CALCULATOR_SET } from '../config';

export const useIsPageWithCalculator = () => {
    const { asPath } = useRouter();
    const [pathWithoutQuery] = asPath.split('?');
    const hasUtmLabel = useHasUtmLabel();

    return PAGES_WITH_CALCULATOR_SET.has(pathWithoutQuery) && !hasUtmLabel;
};
