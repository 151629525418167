import type { CalculatorState } from '../../../types';

const PERCENTS = 100;

export const buildCalculations = (values: CalculatorState) => {
    const { amount = 0, rate = 0, term = 0, penaltyRate = 0, penaltyTerm = 0 } = values;

    const overpayment = (amount * term * rate) / PERCENTS;
    const roundedOverpayment = Math.round(overpayment * PERCENTS) / PERCENTS;

    const penalty = (amount * penaltyRate * penaltyTerm) / PERCENTS;
    const roundedPenalty = Math.round(penalty * PERCENTS) / PERCENTS;

    const total = amount + overpayment + penalty;
    const roundedTotal = Math.round(total * PERCENTS) / PERCENTS;

    return [
        {
            name: 'Сумма займа',
            legendName: 'Сумма займа',
            value: amount,
            width: (amount / total) * PERCENTS,
            color: '#00AFFF',
        },
        {
            name: 'Переплата по займу',
            legendName: 'Переплата',
            value: roundedOverpayment,
            width: (roundedOverpayment / total) * PERCENTS,
            color: '#FF9500',
        },
        {
            name: 'Штраф за просрочку',
            legendName: 'Штраф',
            value: roundedPenalty,
            width: (roundedPenalty / total) * PERCENTS,
            color: '#C11D1D',
        },
        {
            name: 'Общая сумма выплат',
            value: roundedTotal,
        },
    ];
};
