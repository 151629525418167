import type { FC } from 'react';
import React from 'react';

import { Typography } from '@sravni/react-design-system';

import styles from './CalculatorHeading.module.scss';

interface Props {
    title?: string;
    subtitle?: string;
}

export const CalculatorHeading: FC<Props> = ({ title, subtitle }) => {
    const showHeading = title || subtitle;

    if (!showHeading) {
        return null;
    }

    return (
        <div className={styles.heading}>
            {title && <Typography.Heading level={2}>{title}</Typography.Heading>}
            {subtitle && (
                <Typography.Text className="h-color-D60 h-pt-16" size={14}>
                    {subtitle}
                </Typography.Text>
            )}
        </div>
    );
};
