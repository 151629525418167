import cn from 'classnames';
import type { FC } from 'react';
import { memo } from 'react';

import { Divider } from '@sravni/react-design-system/lib/Divider';

import { HelperText } from '@src/components/details/components/PageHeaderFilters/components/HelperText';
import { AmountControl } from '@src/components/ui/AmountControl';
import { RateControl } from '@src/components/ui/RateControl';
import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';
import { sendChangeInputValueEvent } from '@src/helpers/analyticsEvents/calculator';
import { getDaysPluralize } from '@src/helpers/strings';

import type { CalculatorFieldProps, CalculatorState, CalculatorParams } from '../../../types';

import { RU_AMOUNT_DEFAULT_PROPS, RU_RATE_DEFAULT_PROPS, TERM_PROPS } from './CalculatorFields.config';
import styles from './CalculatorFields.module.scss';
import { Penalty } from './Penalty';

interface IProps {
    values: CalculatorState;
    rateProps?: CalculatorFieldProps;
    amountProps?: CalculatorFieldProps;
    onChange: (value: number | undefined, name: string) => void;
    onReset: (resetParams: CalculatorParams[]) => void;
}

export const CalculatorFields: FC<IProps> = memo(({ values, rateProps, amountProps, onChange, onReset }) => {
    const amount = values[FILTERS_NAMES.AMOUNT];
    const term = values[FILTERS_NAMES.TERM];
    const rate = values[FILTERS_NAMES.RATE];

    const termPostfix = getDaysPluralize(term);

    return (
        <div className={styles.container}>
            <div className={cn(styles.input_block)}>
                <div>
                    <AmountControl
                        className={styles.input}
                        name={FILTERS_NAMES.AMOUNT}
                        value={amount}
                        onChange={onChange}
                        analyticsEvent={sendChangeInputValueEvent}
                        {...amountProps}
                    />
                    <HelperText text={amountProps.helperText} />
                </div>

                <div>
                    <RateControl
                        className={styles.input}
                        name={FILTERS_NAMES.RATE}
                        value={rate}
                        onChange={onChange}
                        analyticsEvent={sendChangeInputValueEvent}
                        {...rateProps}
                    />
                    <HelperText text={rateProps.helperText} />
                </div>

                <div>
                    <TermControl
                        className={styles.input}
                        name={FILTERS_NAMES.TERM}
                        value={term}
                        onChange={onChange}
                        postfix={termPostfix}
                        analyticsEvent={sendChangeInputValueEvent}
                        {...TERM_PROPS}
                    />
                    <HelperText text={TERM_PROPS.helperText} />
                </div>
            </div>

            <Divider className={styles.divider} size={8} />

            <div className={styles.penalty_block}>
                <Penalty values={values} onChange={onChange} onReset={onReset} />
            </div>
        </div>
    );
});

CalculatorFields.displayName = 'CalculatorFields';

CalculatorFields.defaultProps = {
    rateProps: RU_RATE_DEFAULT_PROPS,
    amountProps: RU_AMOUNT_DEFAULT_PROPS,
};
